<template>
  <div class="smallmenu">
    <ul class="top menu-li">
      <li class="list" :key="m.id" v-for="m in list">
        <div class="title-box" @click="m.show = m.show ? 0 : 1">
          <h3 class="link-btn">{{ m.title }}</h3>
          <span class="" v-show="m.show">收起<i class="el-icon-arrow-up"></i></span>
          <span class="" v-show="!m.show">显示<i class="el-icon-arrow-down"></i></span>
        </div>
        <ul class="ul" v-show="m.show">
          <li class="list" :key="c.id" v-for="c in m.children">
            <router-link :to="{ path: '/list', query: { id: c.id, title: c.title, cls: cls }, }">
              <div class="label">
                <img :src="rImg(c.icon)" :onerror="errImg" class="img" alt="" />
                <p>{{ c.title }}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      cls: 2,
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
    this.cls = this.$route.query.cls || 2;
  },
  mounted() {
    // 在组件被挂载之后调用。
    this.getNav();
  },
  methods: {
    async getNav() {
      let cate = this.$y_getKey("cate");
      if (cate) {
        cate.forEach((v) => {
          v.show = 1;
        });
        this.list = cate;
      }
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
// 二级导航
.smallmenu {
  ul {
    list-style: none;
    li {
      list-style: none;
    }
  }

  .menu-li {
    .title-box {
      cursor: pointer;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      margin-bottom: 15px;
    }
    h3 {
      padding: 20px;
      display: inline-block;
      font-size: 16px;
    }
    .ul {
      display: flex;
      flex-wrap: wrap;
      .label {
        text-align: center;
        width: 80px;
        font-size: 12px;
        margin-right: 15px;
        margin-bottom: 15px;
        .img {
          width: 50px;
          border-radius: 7px;
        }
      }
    }
  }

  .active {
    color: #49C265;
  }
}
</style>
